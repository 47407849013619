import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../templates/layout'
import LearnMoreBox from '../components/LearnMoreBox'
import LawSolutions from '../components/LawSolutions'
import ArticleBox from '../components/ArticleBox'
import ArticleFeed from '../components/ArticleFeed'
import { graphql, Link } from 'gatsby'
import SEO from '../components/SEO'

type Props = {
	data: {
		markdownRemark: {
			frontmatter: {
				slug: string
				title: string
				description: string
			}
		}
		allPrismicBlog: {
			nodes: [
				{
					uid: string
					data: {
						meta_description: string
						meta_title: string
						title: {
							text: string
						}
						content: {
							text: string
						}
						category: {
							slug: string
						}
						image: {
							url: string
							alt: string
						}
					}
				},
			]
		}
		allPrismicCategories: {
			nodes: [
				{
					uid: string
					data: {
						is_in_homepage: boolean
						meta_description: string
						meta_title: string
						name: string
						sort_order: number
						icon: {
							url: string
						}
						content: {
							text: string
						}
					}
				},
			]
		}
		allPrismicTeamMembers: {
			nodes: [
				{
					uid: string
					data: {
						biography: {
							text: string
						}
						image: {
							url: string
						}
						name: string
					}
				},
			]
		}
	}
}
const IndexPage = ({ data }: Props) => {
	const articles = data.allPrismicBlog.nodes
	const metaTags = data.markdownRemark.frontmatter
	const categories = data.allPrismicCategories.nodes
	const teamMembers = data.allPrismicTeamMembers.nodes

	return (
		<Layout border={false}>
			<SEO
				title={metaTags.title}
				description={metaTags.description}
				path={metaTags.slug}
			/>
			<StaticImage src='../images/home/hero.svg' alt='' layout='fullWidth' />
			<div className='container lg:mt-20 mt-10 font-merriweather'>
				<h1 className='text-header text-black mb-2'>
					Hukukun Her Alanında Hizmet
				</h1>
				<div className='text-small text-grey md:w-1/2'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
					turpis mole.
				</div>
				<div className='lg:mt-11 mt-7 sm:flex sm:flex-wrap sm:justify-between'>
					{categories.map((field) => {
						return (
							<LearnMoreBox
								key={field.uid}
								imageSrc={<img src={field.data.icon.url} alt='' />}
								className='sm:pr-10 lg:w-1/4 md:w-1/2 w-full'
								header={field.data.name}
								text={field.data.content?.text}
								link={`/${field.uid}`}
							/>
						)
					})}
				</div>
				<LawSolutions />
				<ArticleFeed
					articleCount={3}
					title='Makaleler'
					subtitle='Ek Hukuk olarak güncel hukuki gelişmeleri takip ediyor,
						 değişiklikleri sizlerle paylaşıyor, makaleler kaleme alıyoruz.'
				>
					<div className='md:flex md:gap-10'>
						{articles.map((article, a) => {
							return (
								<ArticleBox
									key={`${article.data.title}-${a.toString()}`}
									link={`/${article.data.category.slug}/${article.uid}`}
									className='basis-1/3'
									header={article.data.title.text}
									imageSrc={article.data.image.url}
									imageAlt={article.data.image.alt}
									text={article.data.content.text}
								/>
							)
						})}
					</div>
				</ArticleFeed>
			</div>
			<div className='bg-cream font-merriweather py-16'>
				<div className='container lg:flex justify-between'>
					<div className='lg:mr-10 lg:mb-0 mb-10 lg:w-1/2'>
						<h2 className='text-header text-black'>Uzman Ekip</h2>
						<div className='text-small text-grey mt-2'>
							Quos sed libero magnam recusandae aut illum. Voluptatem et et
							repellendus voluptatem maxime.
						</div>
					</div>
					<div className='sm:flex justify-between items-center lg:flex-nowrap sm:flex-wrap 3xl:w-[45%] xl:w-[54%] lg:w-8/12'>
						{teamMembers.map((member, m) => {
							return (
								<div
									className='flex sm:flex-col items-center md:mb-0 mb-8 sm:w-1/3'
									key={`${member.data.name}-${m.toString()}`}
								>
									<Link to={`/${member.uid}`} title={member.data.name}>
										<img src={member.data.image.url} alt='' />
									</Link>
									<div className='font-poppins font-medium text-lg sm:mt-8 sm:ml-0 ml-8'>
										<Link to={`/${member.uid}`}>{member.data.name}</Link>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query HomeQuery {
		markdownRemark(fileAbsolutePath: { regex: "/home.md/" }) {
			frontmatter {
				slug
				title
				description
			}
		}
		allPrismicBlog(limit: 3, sort: { fields: data___date }) {
			nodes {
				uid
				data {
					meta_description
					meta_title
					title {
						text
					}
					content {
						text
					}
					category {
						slug
					}
					image {
						url
						alt
					}
				}
			}
		}
		allPrismicCategories(sort: { fields: data___sort_order }) {
			nodes {
				uid
				data {
					is_in_homepage
					meta_description
					meta_title
					name
					sort_order
					icon {
						url
					}
					content {
						text
					}
				}
			}
		}
		allPrismicTeamMembers {
			nodes {
				uid
				data {
					biography {
						text
					}
					image {
						url
					}
					name
				}
			}
		}
	}
`
export default IndexPage
